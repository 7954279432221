.carouselArrows {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: flex-end;
    margin-bottom: 16px;

    .leftArrow {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        left: 0;
        border-radius: var(--border-radius-capsule);
        border: var(--line-width-small) solid var(--base-button-secondary-border);
        background: var(--overlay-button-primary-border);
        transform: rotate(90deg);

        svg {
            width: var(--icon-large);
            height: var(--icon-large);
            fill: var(--base-button-secondary-contrast);
            transform: none !important;
        }
    }

    .rightArrow {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        width: 56px;
        height: 56px;
        border-radius: var(--border-radius-capsule);
        border: var(--line-width-small) solid var(--base-button-secondary-border);
        background: var(--overlay-button-primary-border);
        transform: rotate(270deg);

        svg {
            width: var(--icon-large);
            height: var(--icon-large);
            fill: var(--base-button-secondary-contrast);
        }
    }

    .rightArrow:disabled,
    .leftArrow:disabled {
        pointer-events: none;
        cursor: default;
    }
}
