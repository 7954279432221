@use '@/styles/global' as g;

.cardsContainer {
    padding: 0;

    @include g.bp(lg) {
        padding: unset;
    }
}

.wrapper {
    h5:last-of-type {
        margin-top: 56px;
    }
}

.title {
    font-family: 'Helvetica Display', "Helvetica Now", arial, sans-serif;
    font-size: 20px;
    margin-bottom: 24px;
}

.centerTitle {
    @include g.bp(md) {
        font-size: 24px;
        margin-bottom: 24px;
        text-align: center;
    }
}

.slider.fullWidthTrack {
    div:last-of-type {
        width: 100%;
    }
}

.slider {
    display: flex;
    flex-direction: column;
    align-items: center;

    div:last-of-type {
        width: 100%;
    }

    .sliderTrack {
        padding: 0 16px !important;
        max-width: 100%;

        @include g.bp(md) {
            padding: 0 80px !important;
        }
    }

    .carouselArrows {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: flex-end;
        margin-bottom: 16px;
        z-index: 10 !important;

        .leftArrow {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 56px;
            height: 56px;
            left: 0;
            border-radius: var(--border-radius-capsule);
            border: var(--line-width-small) solid var(--base-button-secondary-border);
            background: var(--overlay-button-primary-border);
            transform: rotate(90deg);

            svg {
                width: var(--icon-large);
                height: var(--icon-large);
                fill: var(--base-button-secondary-contrast);
                transform: none !important;
            }
        }

        .rightArrow {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            width: 56px;
            height: 56px;
            border-radius: var(--border-radius-capsule);
            border: var(--line-width-small) solid var(--base-button-secondary-border);
            background: var(--overlay-button-primary-border);
            transform: rotate(270deg);

            svg {
                width: var(--icon-large);
                height: var(--icon-large);
                fill: var(--base-button-secondary-contrast);
            }
        }

        .rightArrow:disabled,
        .leftArrow:disabled {
            pointer-events: none;
            cursor: default;
        }
    }
}

.slider > div:nth-of-type(-n+2) {
    overflow-x: clip;
    overflow-y: visible;
    z-index: 9999;
}
